import { delay, put, select } from 'redux-saga/effects';
import {
  GET_MESSAGES_REQUESTED, GET_UNREAD_MESSAGES_REQUESTED,
  GET_COLLEAGUES_REQUESTED, WORKTIME_GET_WORKTIME_REQUESTED,
  WORKTIME_GET_WORKTIME_CALENDAR_REQUESTED,
  EVENTS_GET_EVENTS_REQUESTED,
} from 'consts/actionTypes';

export const getWorktimeReducer = (store) => store.worktimeReducer;

export function* main() {
  while (true) {
    yield delay(300000);
    console.log("Auto reloading...");
    yield put({ type: GET_MESSAGES_REQUESTED });
    yield put({ type: GET_UNREAD_MESSAGES_REQUESTED });
    if (window.location.pathname.includes('colleagues')) {
      yield put({ type: GET_COLLEAGUES_REQUESTED });
    }
    if (window.location.pathname.includes('dashboard')) {
      const worktimeReducer = yield select(getWorktimeReducer);
      const date = worktimeReducer.worktimeCalendarDate
      yield put({ type: WORKTIME_GET_WORKTIME_REQUESTED });
      yield put({ type: WORKTIME_GET_WORKTIME_CALENDAR_REQUESTED,
        payload: {
          date,
        },
      });
      yield put({ type: EVENTS_GET_EVENTS_REQUESTED });
    }
  }
}

export const mainSagas = main;
