import { GET_ORG_CHART_SUCCEEDED } from 'consts/actionTypes';
import initialState from 'reducers/orgchart/initialState';

const orgchartReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORG_CHART_SUCCEEDED: {
            const {
                orgchartData,
            } = action.payload;
            return {
                ...state,
                orgchartData,
            };
        }

        default:
            return state;
    }
}

export default orgchartReducer;
