/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChartTitle from '../ChartTitle/ChartTitle';
import SpinnerContainer from 'components/SpinnerContainer/SpinnerContainer';
import { Button, Table } from 'antd/lib';
import "./customStyles.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getWorktimeCalendarFilterChanged } from 'actions/worktimeActions';
import { parse, format, subMonths, addMonths } from 'date-fns';

class WorktimeCalendarContainer extends Component {
    componentDidMount() {
        
    }

    render() {
        const {
            isLoading,
            worktimeCalendar,
            worktimeCalendarDate,
            dispatchGetWorktimeCalendarFilterChanged,
        } = this.props;

        if (isLoading) {
            return (
                <div className="worktime-chart-main-container" style={{ marginBottom: '16px' }}>
                    <SpinnerContainer className="barchart-spinner-container" />
                </div>
            );
        }

        const formatColumn = value => {
            if (!value || !value.cell_title) return;

            let style = {}
            if (value.public_holiday) {
                style = {
                    backgroundColor: null,
                }
            }
            if (value.weekend) {
                style = {
                    backgroundColor: '#f7f7f7',
                }
            }

            return (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        padding: '5px',
                        boxSizing: 'border-box',
                        ...style,
                    }}
                >
                    <div style={{ width: '100%', height: '20px', paddingBottom: '5px', textAlign: 'right', boxSizing: 'border-box' }}>{value.cell_title}</div>
                    <div style={{ display: 'flex' }}>
                        {value.work_title && (<div style={{ width: '49%', height: '38px', padding: '3px', marginLeft: '2px', borderRadius: '3px', boxSizing: 'border-box', border: 'solid 1px rgba(0,0,0,0.2)', backgroundColor: '#67e49b' }}>
                            <div style={{ fontWeight: 'bold', fontSize: '10px' }}>{value.work_title}</div>
                            <div style={{ fontSize: '10px' }}>{value.work_content}</div>
                        </div>)}
                        {value.standby_title && (<div style={{ width: '49%', height: '38px', padding: '3px', marginLeft: '2px', borderRadius: '3px', boxSizing: 'border-box', border: 'solid 1px rgba(0,0,0,0.2)', backgroundColor: '#D1E47E' }}>
                            <div style={{ fontWeight: 'bold', fontSize: '10px' }}>{value.standby_title}</div>
                            <div style={{ fontSize: '10px' }}>{value.standby_content}</div>
                        </div>)}
                        {value.public_holiday && (<div style={{ width: '49%', height: '38px', padding: '3px', marginLeft: '2px', borderRadius: '3px', boxSizing: 'border-box', border: 'solid 1px rgba(0,0,0,0.2)', backgroundColor: '#f08887' }}>
                            <div style={{ fontWeight: 'bold', fontSize: '10px' }}>Public holiday</div>
                        </div>)}
                        {value.absence && (<div style={{ width: '49%', height: '38px', padding: '3px', marginLeft: '2px', borderRadius: '3px', boxSizing: 'border-box', border: 'solid 1px rgba(0,0,0,0.2)', backgroundColor: '#71b6f9' }}>
                            <div style={{ fontWeight: 'bold', fontSize: '10px' }}>Absence</div>
                        </div>)}
                        {value.sick_leave && (<div style={{ width: '49%', height: '38px', padding: '3px', marginLeft: '2px', borderRadius: '3px', boxSizing: 'border-box', border: 'solid 1px rgba(0,0,0,0.2)', backgroundColor: '#f3b656' }}>
                            <div style={{ fontWeight: 'bold', fontSize: '10px' }}>Sick leave</div>
                        </div>)}
                        <div style={{ width: '0px', height: '38px' }}></div>
                    </div>
                </div>
            );
        }

        const columns = [
            {
                title: 'Monday',
                dataIndex: 'monday',
                key: 'monday',
                render: value => formatColumn(value),
                width: '12%',
            },
            {
                title: 'Tuesday',
                dataIndex: 'tuesday',
                key: 'tuesday',
                render: value => formatColumn(value),
                width: '12%',
            },
            {
                title: 'Wednesday',
                dataIndex: 'wednesday',
                key: 'wednesday',
                render: value => formatColumn(value),
                width: '12%',
            },
            {
                title: 'Thursday',
                dataIndex: 'thursday',
                key: 'thursday',
                render: value => formatColumn(value),
                width: '12%',
            },
            {
                title: 'Friday',
                dataIndex: 'friday',
                key: 'friday',
                render: value => formatColumn(value),
                width: '12%',
            },
            {
                title: 'Saturday',
                dataIndex: 'saturday',
                key: 'saturday',
                render: value => formatColumn(value),
                width: '12%',
            },
            {
                title: 'Sunday',
                dataIndex: 'sunday',
                key: 'sunday',
                render: value => formatColumn(value),
                width: '12%',
            },
        ];

        const data = [];

        worktimeCalendar && worktimeCalendar.data.forEach(row => {
            data.push({ ...row.attributes, });
        });

        const goBack = () => {
            const date = subMonths(parse(worktimeCalendarDate, "yyyy-MM-dd", new Date()), 1);

            dispatchGetWorktimeCalendarFilterChanged(format(date, "yyyy-MM-dd"));
        }

        const goForward = () => {
            const date = addMonths(parse(worktimeCalendarDate, "yyyy-MM-dd", new Date()), 1);

            dispatchGetWorktimeCalendarFilterChanged(format(date, "yyyy-MM-dd"));
        }

        return (
            <div className="worktime-chart-main-container" style={{ marginBottom: '16px', overflow: 'auto' }}>
                <div style={{ minWidth: '800px' }}>
                    <ChartTitle
                        chartTitle="worktime calendar"
                        iconName="IosClock"
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button icon="left" onClick={() => goBack()}></Button>
                        <div style={{ lineHeight: '32px' }}>{format(parse(worktimeCalendarDate, "yyyy-MM-dd", new Date()), "MMMM yyyy")}</div>
                        <Button icon="right" onClick={() => goForward()}></Button>
                    </div>
                    <Table columns={columns} dataSource={data} pagination={false} rowClassName={"custom-row"} />
                </div>
            </div>
        )
    }
}

WorktimeCalendarContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    worktimeCalendar: PropTypes.arrayOf(PropTypes.shape({})),
};
  
WorktimeCalendarContainer.defaultProps = {
};

const mapStateToProps = (store) => ({
    worktimeCalendarDate: store.worktimeReducer.worktimeCalendarDate,
});

const mapDispatchToProps = {
    dispatchGetWorktimeCalendarFilterChanged: getWorktimeCalendarFilterChanged,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorktimeCalendarContainer);
