import {
  WORKTIME_GET_WORKTIME_REQUESTED,
  WORKTIME_GET_WORKTIME_SUCCEEDED,
  WORKTIME_GET_WORKTIME_FAILED,
  WORKTIME_GET_WORKTIMELIST_REQUESTED,
  WORKTIME_GET_WORKTIMELIST_SUCCEEDED,
  WORKTIME_GET_WORKTIMELIST_FAILED,
  WORKTIME_EMPTY_WORKTIMELIST,
  USER_LOGOUT_SUCCEEDED,
  COMMON_TABLE_SELECT_PAGER,
  WORKTIME_GET_WORKTIME_CALENDAR_SUCCEEDED,
  WORKTIME_GET_WORKTIME_CALENDAR_REQUESTED,
  WORKTIME_GET_WORKTIME_CALENDAR_FILTER_CHANGED,
} from 'consts/actionTypes';
import initialState from 'reducers/worktime/initialState';

const worktimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case WORKTIME_GET_WORKTIME_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case WORKTIME_GET_WORKTIME_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        dailyWorktimeList: action.payload.dailyWorktimeList,
        tasks: action.payload.tasks,
        pmLoggedTime: action.payload.pmLoggedTime,
        pmWorkHours: action.payload.pmWorkHours,
        firstWeekData: action.payload.firstWeekData,
        firstWeekTitle: action.payload.firstWeekTitle,
        secondWeekData: action.payload.secondWeekData,
        secondWeekTitle: action.payload.secondWeekTitle,
        expectedHours: action.payload.expectedHours,
        myWorkTimeFormatted: action.payload.myWorkTimeFormatted,
        todayFormatted: action.payload.todayFormatted,
        myWorkTimeInHours: action.payload.myWorkTimeInHours,
        monthlyWorktime: action.payload.monthlyWorktime,
        hasLoadingError: false,
        errorMessage: '',
      };

    case WORKTIME_GET_WORKTIME_CALENDAR_REQUESTED:
      return {
        ...state,
        isCalendarLoading: true,
      };

    case WORKTIME_GET_WORKTIME_CALENDAR_SUCCEEDED:
      return {
        ...state,
        isCalendarLoading: false,
        worktimeCalendar: action.payload.response,
      };

    case WORKTIME_GET_WORKTIME_CALENDAR_FILTER_CHANGED:
      return {
        ...state,
        worktimeCalendarDate: action.payload.date,
      };

    case WORKTIME_GET_WORKTIME_FAILED:
      return {
        ...state,
        isLoading: false,
        hasLoadingError: true,
        errorMessage: action.payload,
      };

    case WORKTIME_GET_WORKTIMELIST_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };

    case WORKTIME_GET_WORKTIMELIST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        worktimeList: action.payload.worktimeList,
        date: action.payload.date,
      };

    case WORKTIME_GET_WORKTIMELIST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasLoadingError: true,
        errorMessage: action.payload,
      };

    case WORKTIME_EMPTY_WORKTIMELIST:
      return {
        ...state,
        worktimeList: null,
      };

    case COMMON_TABLE_SELECT_PAGER:
      return {
        ...state,
        worktimeList: null,
      };

    case USER_LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};

export default worktimeReducer;
