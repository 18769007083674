import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import $ from "jquery";
import "orgchart/dist/css/jquery.orgchart.css";
import "orgchart";
import "./MyOrgChart.css";
import { getOrgchart } from "actions/orgchartActions";
import { connect, useDispatch } from "react-redux";
import BreadCrumb from "components/BreadCrumb/BreadCrumb";
import './orgchart-page.scss';

const Orgchart = ({ orgchartApiData }) => {
    const chartRef = useRef(null);
    const dispatch = useDispatch();
    const [orgChartData, setOrgChartData] = useState({});
    const [selectedNode, setSelectedNode] = useState(null);

    const loadOrgChart = () => {
        if (!chartRef.current) {
            return;
        }

        console.log('Load orchart...');
        $(chartRef.current).empty();
        const oc = $(chartRef.current).orgchart({
            data: orgChartData,
            nodeContent: "title",
            verticalLevel: 3,
            collapsible: false,
            createNode: function($node, data) {
                $node.addClass(data.class);
                let image = '<div class="node-image">';
                //if (data.img) {
                    image += `<img src="${data.img}" onerror="this.onerror=null; this.src='/image/person-white.png'" />`;
                //}
                image += '</div>';
                $node.prepend(image);
                $node.prepend(`<div class="top-content ${data.division ? 'division' : ''}">${data.division ?? ''}</div>`);
            }
        });
        oc.$chartContainer.on('click', '.node', function() {
            var $this = $(this);
            setSelectedNode($this.attr('id'));
        });
      
        oc.$chartContainer.on('click', '.orgchart', function(event) {
            if (!$(event.target).closest('.node').length) {
                setSelectedNode(null);
            }
        });
    }

    const loadOrgChartData = () => {
        dispatch(getOrgchart());
    }

    useEffect(() => {
        loadOrgChartData();
    }, []);

    useEffect(() => {
        const apiData = JSON.parse(JSON.stringify(orgchartApiData));
        setOrgChartData(apiData);
    }, [orgchartApiData]);

    useEffect(() => {
        console.log(selectedNode);
    }, [selectedNode]);

    useEffect(() => {
        loadOrgChart();
    }, [orgChartData]);

    return (
        <div className="page page-orgchart">
            <div className="orgchart-page-title">Organization chart</div>
            <div className="orgchart-page-container">
                <div ref={chartRef} id="org-chart-container"></div>
            </div>
        </div>
    )
}

Orgchart.propTypes = {
    orgchartApiData: PropTypes.shape({}).isRequired,
};

Orgchart.defaultProps = {
};

const mapStateToProps = (state) => ({
    orgchartApiData: state.orgchartReducer.orgchartData,
});

export default connect(mapStateToProps)(Orgchart);
