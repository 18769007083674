import { format, startOfMonth } from "date-fns";

const initialState = {
  isLoading: false,
  isCalendarLoading: false,
  hasLoadingError: false,
  errorMessage: '',
  dailyWorktimeList: null,
  tasks: null,
  pmLoggedTime: null,
  firstWeekData: null,
  firstWeekTitle: null,
  secondWeekData: null,
  secondWeekTitle: null,
  expectedHours: null,
  myWorkTimeFormatted: null,
  todayFormatted: null,
  myWorkTimeInHours: null,
  monthlyWorktime: null,
  worktimeList: null,
  date: null,
  pmWorkHours: null,
  worktimeCalendar: null,
  worktimeCalendarDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
};

export default initialState;
