import {
    GET_ORG_CHART_FAILED,
    GET_ORG_CHART_REQUESTED,
    GET_ORG_CHART_SUCCEEDED,
} from 'consts/actionTypes';
import { host } from 'services/config';
import { put, select, takeEvery, call } from 'redux-saga/effects';
import { openNotificationWithIcon } from 'services/utils';
import * as API from 'services/api';

export function* getOrgchart() {
    try {
        const orgchart = yield call(API.getData, `${host}/api/user/org-chart`);
        yield put({
            type: GET_ORG_CHART_SUCCEEDED,
            payload: {
                orgchartData: orgchart,
            },
        });
    } catch (error) {
        yield put({
            type: GET_ORG_CHART_FAILED,
            payload: {
                errorMessage: error.message,
            },
        });
        console.log(error.message); // eslint-disable-line
    }
}

export const orgchartSagas = [
    takeEvery(GET_ORG_CHART_REQUESTED, getOrgchart),
];
