import {
  WORKTIME_GET_WORKTIME_REQUESTED,
  WORKTIME_GET_WORKTIME_CALENDAR_REQUESTED,
  WORKTIME_GET_WORKTIMELIST_REQUESTED,
  WORKTIME_EMPTY_WORKTIMELIST,
  WORKTIME_GET_WORKTIME_CALENDAR_FILTER_CHANGED,
} from 'consts/actionTypes';

export const getWorktime = () => ({
  type: WORKTIME_GET_WORKTIME_REQUESTED,
});

export const getWorktimeCalendar = (date) => ({
  type: WORKTIME_GET_WORKTIME_CALENDAR_REQUESTED,
  payload: {
    date,
  },
});

export const getWorktimeCalendarFilterChanged = (date) => {
  return {
    type: WORKTIME_GET_WORKTIME_CALENDAR_FILTER_CHANGED,
    payload: {
      date,
    },
  }
};

export const getWorktimeList = (date) => ({
  type: WORKTIME_GET_WORKTIMELIST_REQUESTED,
  payload: {
    date,
  },
});

export const emptyWorktimeList = (date) => ({
  type: WORKTIME_EMPTY_WORKTIMELIST,
  payload: {
    date,
  },
});
