import { all } from 'redux-saga/effects';
import { absenceSagas } from 'sagas/absenceSaga';
import { appSagas } from 'sagas/appSaga';
import { bookmarkSagas } from 'sagas/bookmarkSaga';
// import { bonusSagas } from 'sagas/bonusSaga';
import { colleaguesSagas } from 'sagas/colleaguesSaga';
import { eventSagas } from 'sagas/eventSaga';
// import { officeMapSagas } from 'sagas/officeMapSaga';
import { mainSagas } from 'sagas/mainSaga';
// import { messagesSagas } from 'sagas/messagesSaga';
import { tableSagas } from 'sagas/tableSaga';
import { userSagas } from 'sagas/userSaga';
import { profileSagas } from 'sagas/profileSaga';
import { worktimeSagas } from 'sagas/worktimeSaga';
import { wikiSagas } from 'sagas/wikiSaga';
import { orgchartSagas } from 'sagas/orgchartSaga';

export default function* rootSaga() {
  yield all([
    ...absenceSagas,
    ...appSagas,
    ...bookmarkSagas,
    // ...bonusSagas,
    ...colleaguesSagas,
    ...eventSagas,
    // ...messagesSagas,
    // ...officeMapSagas,
    ...tableSagas,
    ...userSagas,
    ...profileSagas,
    ...worktimeSagas,
    ...wikiSagas,
    ...orgchartSagas,
    mainSagas(),
  ]);
}
