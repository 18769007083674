import React from 'react';
import PropTypes from 'prop-types';
import IosHome from 'react-ionicons/lib/IosHome';
import IosClock from 'react-ionicons/lib/IosClock';
import IosSunny from 'react-ionicons/lib/IosSunny';
import IosPeople from 'react-ionicons/lib/IosPeople';
import MdHelp from 'react-ionicons/lib/MdHelp';
import MdGitNetwork from 'react-ionicons/lib/MdGitNetwork';
import { Menu } from 'antd';
import './sider-menu.scss';
import { CompanyLogo } from '../../utils/branding';


const routes = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: IosHome,
  },
  {
    path: '/status-and-worktime',
    title: 'Status & Worktime',
    table: '/status',
    icon: IosClock,
  },
  {
    path: '/absence',
    title: 'Absence',
    icon: IosSunny,
  },
  {
    path: '/colleagues',
    title: 'Colleagues',
    icon: IosPeople,
  },
  {
    path: '/orgchart',
    title: 'Org Chart',
    icon: MdGitNetwork,
  },
  {
    path: '/wiki',
    title: 'Wiki',
    icon: MdHelp,
  },
];

export const SiderMenu = ({ selectedKey, collapsed, handleClick }) => (
  <Menu className="sider-menu-container" selectedKeys={[selectedKey]} onClick={handleClick}>
    <div
      className="sider-menu-logo"
      tabIndex={0}
      role="button"
      onKeyPress={() => { }}
      onClick={() => { handleClick({ key: '/dashboard', item: { props: '/dashboard' } }); }}
    >
      <CompanyLogo />
    </div>
    {
      routes.map((menuItem) => (
        <Menu.Item className="menu-item-elements" table={menuItem.table || null} title={menuItem.title} key={menuItem.path}>
          <menuItem.icon fontSize="16px" color="#fff" />
          {
            !collapsed && (<span style={{ marginLeft: '12px' }}>{menuItem.title}</span>)
          }
        </Menu.Item>
      ))
    }
  </Menu>
);

SiderMenu.propTypes = {
  collapsed: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  selectedKey: PropTypes.string.isRequired,
};

SiderMenu.defaultProps = {
  collapsed: false,
};

export default SiderMenu;
